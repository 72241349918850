@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 260 40% 98%; /* Very light purple */
    --foreground: 260 50% 15%; /* Dark purple for text */

    --card: 0 0% 100%; /* White */
    --card-foreground: 260 50% 15%; /* Dark purple for text on cards */

    --popover: 0 0% 100%; /* White */
    --popover-foreground: 260 50% 15%; /* Dark purple for text on popovers */

    --primary: 260 80% 50%; /* Vibrant purple */
    --primary-foreground: 0 0% 100%; /* White text on primary */

    --secondary: 280 70% 50%; /* Darker purple-pink */
    --secondary-foreground: 0 0% 100%; /* White text on secondary */

    --muted: 260 20% 90%; /* Light purple for muted areas */
    --muted-foreground: 260 30% 40%; /* Muted purple text */

    --accent: 280 50% 85%; /* Light purple-pink for accents */
    --accent-foreground: 260 50% 15%; /* Dark purple text on accents */

    --destructive: 0 100% 50%; /* Red for destructive actions */
    --destructive-foreground: 0 0% 100%; /* White text on destructive */

    --border: 260 20% 80%; /* Light purple for borders */
    --input: 260 20% 90%; /* Light purple for input backgrounds */
    --ring: 260 80% 50%; /* Vibrant purple for focus rings */

    --radius: 0.75rem; /* Slightly larger border radius */
  }

  .dark {
    --background: 260 40% 10%; /* Dark purple background */
    --foreground: 260 20% 90%; /* Light purple text */

    --card: 260 40% 15%; /* Slightly lighter dark purple for cards */
    --card-foreground: 260 20% 90%; /* Light purple text on cards */

    --popover: 260 40% 15%; /* Slightly lighter dark purple for popovers */
    --popover-foreground: 260 20% 90%; /* Light purple text on popovers */

    --primary: 260 80% 60%; /* Lighter vibrant purple for dark mode */
    --primary-foreground: 260 40% 10%; /* Dark purple text on primary */

    --secondary: 280 70% 60%; /* Lighter darker purple-pink for dark mode */
    --secondary-foreground: 260 40% 10%; /* Dark purple text on secondary */

    --muted: 260 30% 30%; /* Darker muted purple */
    --muted-foreground: 260 20% 70%; /* Lighter muted purple text */

    --accent: 280 40% 30%; /* Darker accent purple-pink */
    --accent-foreground: 260 20% 90%; /* Light purple text on accent */

    --destructive: 0 100% 40%; /* Darker red for destructive actions */
    --destructive-foreground: 0 0% 100%; /* White text on destructive */

    --border: 260 30% 30%; /* Darker purple borders for dark mode */
    --input: 260 30% 25%; /* Darker purple input backgrounds for dark mode */
    --ring: 260 80% 60%; /* Lighter purple ring color for dark mode */
  }
}

@layer utilities {
  .bg-gradient-primary {
    @apply bg-gradient-to-br from-purple-700 via-purple-600 to-purple-500;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

